import React from "react";
import { Button } from "react-bootstrap";
import downloads from "../../../assets/img/svg/downloads.svg";
import config from "../../../config";
import { InvoiceService } from "../../../services/InvoiceService";
import ShopService, { Organization } from "../../../services/ShopService";
import OrganizationControl, { OrganizationControlState } from "../../OrganizationPage/OrganizationControl";

const OrganizationOrder: React.FC = () => {
  enum Status {
    list,
    add,
  }
  const [organizationid, setOrganizationid] = React.useState("");
  const [items, setItems] = React.useState([] as Organization[]);
  const [status, setStatus] = React.useState<Status>(Status.list);
  const [enabledownload, setEnabledownload] = React.useState(true);
  const [invoice, setInvoice] = React.useState({} as any);

  const onSaveCallback = (organization: Organization) => {
    setItems([...items, organization]);
    setStatus(Status.list);
  };

  const onCancelCallback = () => {
    setStatus(Status.list);
  };

  const downloadPDF = async (invoiceid: string) => {
    // if (!invoice.invoiceid) {
    // alert("Заказ не сформирован.");
    // return;
    // }
    const accessToken = localStorage.getItem("token");
    setEnabledownload(false);
    const response = await fetch(config.API_URL + "/invoice/download_pdf/" + invoiceid, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status === 200) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Заказ.pdf";
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
    setEnabledownload(true);
  };

  const makeInvoice = () => {
    if (!organizationid) {
      alert("Укажите организацию на которую оформить счет.");
      return;
    }
    if (invoice.invoiceid) {
      if (invoice.organizationid !== organizationid) {
        InvoiceService.updateOrganization(invoice.invoiceid, organizationid)
          .then((res) => {
            setInvoice(res.data);
            downloadPDF(res.data.invoiceid);
          })
          .catch((e: any) => {
            if (e.response) {
              alert(e.response.data.message); // setError(e.response.data.message);
            } else if (e.request) {
              alert(e.request); // setError(e.request);
            } else {
              alert(e.message); // setError(e.message);
            }
          });
      } else {
        downloadPDF(invoice.invoiceid);
      }
      return;
    }

    InvoiceService.makeFromCart("", organizationid)
      .then((res) => {
        setInvoice(res.data);
        downloadPDF(res.data.invoiceid);
      })
      .catch((e: any) => {
        if (e.response) {
          alert(e.response.data.message); // setError(e.response.data.message);
        } else if (e.request) {
          alert(e.request); // setError(e.request);
        } else {
          alert(e.message); // setError(e.message);
        }
      });
  };
  const effectFunc = () => {
    ShopService.organizationGet()
      .then((res) => {
        setItems(res.data.items);
      })
      .catch((e: any) => {
        if (e.response) {
          console.log(e.response.data);
        } else {
          console.log("Error", e.message);
        }
      });
  };

  React.useEffect(effectFunc, []);
  return (
    <>
      {status === Status.list && (
        <>
          <h2>Оформить на:</h2>
          <select className="w-100 rounded p-2" value={organizationid} onChange={(e) => setOrganizationid(e.target.value)}>
            <option className="py-3" disabled value="">
              --Укажите юр. лицо--
            </option>
            {items.map((item: Organization) => (
              <option className="py-3" value={item.organizationid} key={item.organizationid}>
                {item.name}
              </option>
            ))}
          </select>
          <Button variant="outline-secondary" className="w-100 my-3 border" onClick={() => setStatus(Status.add)}>
            + Добавить новую организацию
          </Button>
          <Button className="style-btn-action mt-5 px-5 py-3 w-100" disabled={!enabledownload} onClick={makeInvoice}>
            <img className="me-2" src={downloads} alt="download-price" />
            Скачать счет
          </Button>
        </>
      )}
      {status === Status.add && <OrganizationControl state={OrganizationControlState.add} organization={{} as Organization} saveCallback={onSaveCallback} cancelCallback={onCancelCallback} />}
    </>
  );
};

export default OrganizationOrder;
