import React from "react";

import { Accordion, Col, Container, Row } from "react-bootstrap";
import { ClientLicScheme } from "../../services/ClientService";

import cn from "classnames";
import s from "./Device.module.scss";

enum LicSchemeMode {
  OnExecute,
  OnConnect,
}

enum LicSchemeType {
  Computer,
  User,
  Concurent,
}

function licSchemeTypeToString(licSchemeType: LicSchemeType) {
  let res = "";
  switch (licSchemeType) {
    case LicSchemeType.Computer:
      res = "На компьютер";
      break;
    case LicSchemeType.Concurent:
      res = "Конкурентная";
      break;
    case LicSchemeType.User:
      res = "На пользователя";
      break;
  }
  return res;
}

function licSchemeModeToString(licSchemeMode: LicSchemeMode) {
  let res = "";
  switch (licSchemeMode) {
    case LicSchemeMode.OnConnect:
      res = "На подключение";
      break;
    case LicSchemeMode.OnExecute:
      res = "На запуск";
      break;
  }
  return res;
}

export type ClientDeviceItemProps = {
  name: string;
  infodata: string;
  clientlicschemes: ClientLicScheme[];
};

const ClientDeviceItem: React.FC<ClientDeviceItemProps> = ({ name, clientlicschemes }) => {
  return (
    <Accordion className={cn("rounded-3 p-0 mb-4", s.accordion)} flush>
      <Accordion.Item eventKey="0" className="d-sm-none">
        <Accordion.Header className={cn("rounded-3", s.accordion_button_device)}>
          <div className="fs-5">Устройство: {name}</div>
        </Accordion.Header>
        <Accordion.Body>
          <Container>
            {clientlicschemes.map((item) => (
              <div className="mb-4" key={item.clientlicschemeid}>
                <h3 className="fw-bold">Лицензиия: {item.name}</h3>
                <hr />
                <Row>
                  <Col>
                    <p style={{ height: "40px" }}>Продукт:</p>
                    <p style={{ height: "40px" }}>Тип:</p>
                    <p style={{ height: "40px" }}>Режим:</p>
                  </Col>
                  <Col>
                    <p style={{ height: "40px" }}>{item.productname}</p>
                    <p style={{ height: "40px" }}>{licSchemeTypeToString(item.lictype)}</p>
                    <p style={{ height: "40px" }}>{licSchemeModeToString(item.licmode)}</p>
                  </Col>
                </Row>
              </div>
            ))}
          </Container>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="0" className="d-none d-sm-block rounded-3">
        <Accordion.Header className={cn("rounded-3", s.accordion_button_device)}>
          <div className="fs-5">Устройство: {name}</div>
        </Accordion.Header>
        <Accordion.Body>
          <Container className="px-0 mx-0 w-100">
            <Row className={cn("justify-content-center w-100 fw-bold", s.device_topics)} style={{ borderBottom: "2px solid #e78941" }}>
              <Col>Лицензии</Col>
              <Col>Продукт</Col>
              <Col>Тип</Col>
              <Col>Режим</Col>
            </Row>
            {clientlicschemes.map((item) => (
              <Row className={cn(" mt-3", s.device_info)} key={item.clientlicschemeid}>
                <Col>
                  <p className="p-0">{item.name}</p>
                </Col>
                <Col>
                  <p className="p-0">{item.productname}</p>
                </Col>
                <Col>
                  <p className="p-0">{licSchemeTypeToString(item.lictype)}</p>
                </Col>
                <Col>
                  <p className="p-0">{licSchemeModeToString(item.licmode)}</p>
                </Col>
              </Row>
            ))}
          </Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default ClientDeviceItem;
