import React from "react";
import { Button } from "react-bootstrap";
import UserProfileService from "../../services/UserProfileService";

const ChangeUserDataItem: React.FC = () => {
  const [people, setPeople] = React.useState<any>({ surname: "", name: "", middlename: "", phone: "" });

  React.useEffect(() => {
    UserProfileService.peopleGet()
      .then((res) => {
        setPeople(res.data);
      })
      .catch((e: any) => {
        if (e.response) {
          console.log(e.response.data);
        } else {
          console.log("Error", e.message);
        }
      });
  }, []);

  const onClickApply = () => {
    UserProfileService.peopleUpdate(people)
      .then((res) => {})
      .catch((e: any) => {
        if (e.response) {
          alert(e.response.data);
        } else {
          alert(e.message);
        }
      });
  };

  return (
    <div>
      <div className="mb-3">
        <h5 className="mb-4">Изменить ФИО</h5>
        <label className="form-label mb-1">Фамилия</label>
        <input type="text" className="form-control mb-3" value={people.surname} onChange={(e) => setPeople({ ...people, surname: e.target.value })} />
        <label className="form-label mb-1">Имя</label>
        <input type="text" className="form-control mb-3" value={people.name} onChange={(e) => setPeople({ ...people, name: e.target.value })} />
        <label className="form-label mb-1">Отчество</label>
        <input type="text" className="form-control mb-3" value={people.middlename} onChange={(e) => setPeople({ ...people, middlename: e.target.value })} />
        <label className="form-label mb-1">Номер телефона</label>
        <input type="value" className="form-control mb-3" value={people.phone} onChange={(e) => setPeople({ ...people, phone: e.target.value })} />
      </div>
      <Button variant="secondary" onClick={onClickApply}>
        Применить
      </Button>
    </div>
  );
};

export default ChangeUserDataItem;
