import $api from "../http";
import {AxiosResponse} from 'axios';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

export type ClientLicScheme = {
  clientlicschemeid: string;
  recorddatecreate: Date;
  recordusernamecreate: string;
  recorddateupdate: Date;
  recordusernameupdate: string;
  clientid: string;
  licmode: number;
  lictype: number;
  datefinish: Date;
  daysfrombuy: number;
  daysfromactivation: number;
  launchcount: number;
  connectcount: number;
  productid: string;
  productname: string;
  dateconstraint: number;
  deviceid: string;
  activationdate: Date;
  name: string;
  status: number;
  clientdeviceid: string;
  clientdevicename: string;  
}

export type ClientLicSchemeList = {
  items: ClientLicScheme[];
  totalcount: number;
}

export type ClientDownload = {
  clientdownloadid: string;
  downloaddate: Date;
  clientagent: string;
  recorddatecreate: Date;
  recordusernamecreate: string;
  recorddateupdate: Date;
  recordusernameupdate: string;
  ipaddress: string;
  clientid: string;
  productid: string;
  productname: string;
}

export type ClientDownloadList = {
  items: ClientDownload [];
  totalcount: number;
}

export type ClientDevice = {
  clientdeviceid: string;
  recorddatecreate: Date;
  recordusernamecreate: string;
  recorddateupdate: Date;
  recordusernameupdate: string;
  name: string;
  infodata: string;
  hash: string;
  clientid: string;
  userprofileid: string;
  email: string;
  nickname: string;
  peopleid: string;
  peoplesurname: string;
  peoplename: string;
  peoplemiddlename: string;
  clientlicschemes: ClientLicScheme [];
}

export type ClientDeviceList = {
  items: ClientDevice [];
  totalcount: number;
}

export type ClientInstallation = {
  clientinstallationid: string;
  installationdate: Date;
  productid: string;
  productname: string;
  clientid: string;
  clientsysinfo: string;
  recorddatecreate: Date;
}

export type ClientInstallationList = {
  items: ClientInstallation[];
  totalcount: number;
}

export default class ClientService {
  static async licSchemeGetAll(page: number, limit: number): Promise<AxiosResponse<ClientLicSchemeList>> {
    return $api.get<ClientLicSchemeList>('/licschemes', {
        params: pickBy(                                 
          {
            page,
            limit,
          },
          identity,
        ),
      })
  }

  static async downloadGetAll(page: number, limit: number): Promise<AxiosResponse<ClientDownloadList>> {
    return $api.get<ClientDownloadList>('/downloads', {
      params: pickBy(                                 
        {
          page,
          limit,
        },
        identity,
      ),
    })
  }

  static async deviceGetAll(page: number, limit: number): Promise<AxiosResponse<ClientDeviceList>> {
    return $api.get<ClientDeviceList>('/devices', {
      params: pickBy(                                 
        {
          page,
          limit,
        },
        identity,
      ),
    })
  }  

  static async installGetAll(page: number, limit: number): Promise<AxiosResponse<ClientInstallationList>> {
    return $api.get<ClientInstallationList>('/installations', {
      params: pickBy(                                 
        {
          page,
          limit,
        },
        identity,
      ),
    })
  } 

  static async licSchemeDeviceClear(clientlicschemeid: string): Promise<AxiosResponse<any>> {
    return $api.get<any>('/licschemeclearclientdevice/' + clientlicschemeid);
  } 
}

