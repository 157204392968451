import $api from "../http";
import {Image} from "./ImageService";
import {AxiosResponse} from 'axios';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

export type Characteristic = {
  characteristicrangetype: number;
  value1: string;
  value2: string;
  groupcharacteristicname: string;
  groupcharacteristicparentname: string;
  characteristicname: string;
  characteristicvaluetype: number;
};

export type Nomenclature = {
    nomenclatureid: string;
    name: string;
    namefull: string;
    code: string;
    description: any;
    price: number;
    images: Image[];
    characteristics: Characteristic[];
  };

export type NomenclatureList = {
    totalcount: number;
    items: Nomenclature[];    
  }

export default class NomenclatureService {
    static async getAll(page: number, limit: number, search: string): Promise<AxiosResponse<NomenclatureList>> {
        return $api.get<NomenclatureList>('/nomenclatures',{
            params: pickBy(                                 
              {
                page,
                limit,
                search,
              },
              identity,
            ),
          })
    }

    static async getOne(nomenclatureid: string): Promise<AxiosResponse<Nomenclature>> {
        return $api.get<Nomenclature>('/nomenclature/' + nomenclatureid)
    }

    // static async add(nomenclatureid: String): Promise<AxiosResponse<Cart>> {
    //     const res = $api.get<Cart>(`/cart/add/${nomenclatureid}`);
    //     return res;
    // }

    // static async dec(nomenclatureid: String): Promise<AxiosResponse<Cart>> {
    //     const res = $api.get<Cart>(`/cart/dec/${nomenclatureid}`);
    //     return res;
    // }

    // static async del(nomenclatureid: String): Promise<AxiosResponse<Cart>> {
    //     const res = $api.delete<Cart>(`/cart/del/${nomenclatureid}`);
    //     return res;
    // }
     
}

