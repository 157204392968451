import React from "react";
import { Button } from "react-bootstrap";
import UserProfileService from "../../services/UserProfileService";

const ChangePasswordItem: React.FC = () => {
  const [password_old, setPassword_old] = React.useState("");
  const [password_new1, setPassword_new1] = React.useState("");
  const [password_new2, setPassword_new2] = React.useState("");

  const onClickApply = () => {
    if (!password_old) {
      alert("Не указан старый пароль.");
      return;
    }
    if (!password_new1 || !password_new2) {
      alert("Новые пароль не может быть пустым.");
      return;
    }
    if (password_new1 !== password_new2) {
      alert("Новые пароли не совпадают.");
      return;
    }
    UserProfileService.passwdUpdate({ password_old, password_new: password_new1 })
      .then((res) => {
        alert("Пароль успешно изменён.");
      })
      .catch((e: any) => {
        if (e.response) {
          alert(e.response.data.message);
        } else {
          alert(e.message);
        }
      });
  };

  return (
    <div>
      <div className="mb-3">
        <h5 className="mb-4">Изменить пароль</h5>
        <label className="form-label">Старый пароль</label>
        <input type="password" className="form-control mb-3" value={password_old} onChange={(e) => setPassword_old(e.target.value)} />
        <label className="form-label">Новый пароль</label>
        <input type="password" className="form-control mb-3" value={password_new1} onChange={(e) => setPassword_new1(e.target.value)} />
        <label className="form-label">Повторить пароль</label>
        <input type="password" className="form-control mb-3" value={password_new2} onChange={(e) => setPassword_new2(e.target.value)} />
      </div>

      <Button variant="secondary" onClick={onClickApply}>
        Применить
      </Button>
    </div>
  );
};

export default ChangePasswordItem;
