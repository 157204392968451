import Cookies from "js-cookie";
import React from "react";
import { Button } from "react-bootstrap";
import UserProfileService from "../../services/UserProfileService";

const ChangeDeleteAccountItem: React.FC = () => {
  const onClickDelete = () => {
    UserProfileService.delete()
      .then((res) => {
        localStorage.removeItem("token");
        Cookies.remove("accessToken");
        window.location.href = "/";
      })
      .catch((e: any) => {
        if (e.response) {
          alert(e.response.data.message);
        } else {
          alert(e.message);
        }
      });
  };
  return (
    <div>
      <div>
        <h5>Вы действительно хотите удалить аккаунт?</h5>
      </div>
      <Button variant="danger" onClick={onClickDelete}>
        Удалить
      </Button>
    </div>
  );
};

export default ChangeDeleteAccountItem;
