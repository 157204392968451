import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { logoutUser } from "../../redux/user/asyncActions";
import { selectUser } from "../../redux/user/selectors";
import { selectCart } from "../../redux/cart/selectors";
import LoginModal from "../LoginModal";

import userprofile from "../../assets/img/svg/userprofile.svg";
import cart from "../../assets/img/svg/cart.svg";
import invoice from "../../assets/img/svg/invoice.svg";
import logout from "../../assets/img/svg/logout.svg";
import userheader from "../../assets/img/svg/userheader.svg";
import organization from "../../assets/img/svg/organization.svg";
import Cookies from "js-cookie";
import { Anchor, Badge, Button, Col, Container, Dropdown, Nav, Row } from "react-bootstrap";
import OffcanvasModule from "./OffcanvasModule";

import cn from "classnames";
import s from "./Header.module.scss";

export const Header: React.FC = () => {
  const [isLoginShow, setIsLoginShow] = useState(false);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUser);
  const { items } = useAppSelector(selectCart);
  const totalCount = items.reduce((sum: number, item: any) => sum + item.cnt, 0);
  const location = useLocation();

  // React.useEffect(() => {
  //   if (isMounted.current) {
  //     const json = JSON.stringify(items);
  //     localStorage.setItem('cart', json);
  //   }
  //   isMounted.current = true;
  // }, [items]);

  const onLoginClick = () => {
    setIsLoginShow(!isLoginShow);
    // showLogin('#login');
  };

  return (
    <>
      <header>
        <Row className="align-items-center text-center gx-0">
          <Col xs={3} sm={3} className="d-md-none text-start">
            <OffcanvasModule />
          </Col>
          <Col xs={6} sm={6} md={8} className="d-md-none">
            {location.pathname === "/" && <h2 className="fs-1 m-0">Загрузки</h2>}
            {location.pathname === "/shop" && <h2 className="fs-1 m-0">Магазин</h2>}
            {location.pathname === "/license" && <h2 className="fs-1 m-0">Мои лицензии</h2>}
            {location.pathname === "/device" && <h2 className="fs-1 m-0">Мои устройства</h2>}
            {location.pathname === "/installation" && <h2 className="fs-1 m-0">Инсталяции</h2>}
            {location.pathname === "/downloadlog" && <h2 className="fs-1 m-0">Журнал загрузок</h2>}
            {location.pathname === "/cart" && <h2 className="fs-1 m-0">Корзина</h2>}
            {location.pathname === "/invoice" && <h2 className="fs-1 m-0">Мои заказы</h2>}
            {location.pathname === "/organization" && <h2 className="fs-1 m-0">Мои юр. лица</h2>}
            {location.pathname === "/userprofile" && <h2 className="fs-1 m-0">Параметры аккаунта</h2>}
            {location.pathname === "/nomenclature" && <h2 className="fs-1 m-0">Продукт</h2>}
          </Col>
          <Col xs={3} sm={3}></Col>
        </Row>
        <Container className="top-hat">
          <Row className="align-items-center text-center">
            <Col md={5} lg={5} xl={3} className="d-none d-md-block me-auto">
              <Link to="/">
                <h1 className="my-3">
                  <b>PAUT ITSM</b>
                </h1>
              </Link>
            </Col>

            <Col md={7} lg={7} xl={6} className="menu d-none d-md-block">
              <div className="d-flex text-center align-items-center justify-content-end">
                {!user && (
                  <Col xs={4} sm={4} md={5} lg={3} xl={2} className="d-flex align-items-center p-2">
                    <Button variant="secondary" className=" d-flex p-2 login-btn" type="button" style={{ border: 0, fontSize: "14px" }} onClick={onLoginClick}>
                      <img alt="userheader" className="me-2" src={userheader} />
                      Вход
                    </Button>
                  </Col>
                )}
                {user && (
                  <>
                    <Col xs={4} sm={4} md={8} lg={7} xl={5} className="d-flex align-items-center p-2 justify-content-end">
                      <Link className="dropdown-item align-items-center me-5" to="/cart">
                        <img alt="cart" className="me-2" src={cart} />
                        <span className="text-dark">Корзина</span>
                        <Badge className={cn("position-absolute", s.badge)} bg="primary" pill>
                          {totalCount}
                        </Badge>
                      </Link>
                      <Dropdown>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                          <img alt="userheader" className="me-2" src={userheader} />
                          {user.email}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className={cn("py-0", s.dropdown_menu)}>
                          <div className="">
                            <Link className={cn("py-3", s.dropdown_item)} to="/userprofile">
                              <img alt="userprofile" className="me-2" src={userprofile} />
                              <span className="dropdown-link-label">Параметры аккаунта</span>
                            </Link>

                            <Link className={cn("py-3", s.dropdown_item)} to={"/invoice"}>
                              <img alt="invoice" className="me-2" src={invoice} />
                              <span className="dropdown-link-label">Мои заказы</span>
                            </Link>
                            <Link className={cn("py-3", s.dropdown_item)} to={"/organization"}>
                              <img alt="organization" className="me-2" src={organization} />
                              <span className="dropdown-link-label">Мои юр.лица</span>
                            </Link>
                            <hr className="dropdown-divider m-0" />
                            <Anchor
                              href="/"
                              className={cn("py-3", s.dropdown_item)}
                              onClick={(e) => {
                                dispatch(logoutUser());
                                localStorage.removeItem("token");
                                Cookies.remove("accessToken");
                                window.location.href = "/";
                              }}
                            >
                              <img alt="logout" className="me-2" src={logout} />
                              <span className="dropdown-link-label">Выйти</span>
                            </Anchor>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
        <div className={cn("d-none d-md-block", s.menu)}>
          <Container>
            <Nav className="nav-pills align-items-center" activeKey="/">
              <Row>
                <Col xs={8} sm={8} md={6} lg={9} xl={8} className="justify-content-center d-none d-lg-flex mx-auto">
                  <Link to="/">
                    <Nav.Item className={cn("py-3 px-3", s.nav_item, { [s.nav_link_active]: location.pathname === "/" })}>
                      <h6 className="m-0 animate__animated animate__fadeInDown">Загрузки</h6>
                    </Nav.Item>
                  </Link>
                  <Link to="/shop">
                    <Nav.Item className={cn("p-3", s.nav_item, { [s.nav_link_active]: location.pathname === "/shop" })}>
                      <h6 className="m-0 animate__animated animate__fadeInDown">Магазин</h6>
                    </Nav.Item>
                  </Link>
                  {user && (
                    <>
                      <Link to="/license">
                        <Nav.Item className={cn("p-3", s.nav_item, { [s.nav_link_active]: location.pathname === "/license" })}>
                          <h6 className="m-0 animate__animated animate__fadeInDown">Мои лицензии</h6>
                        </Nav.Item>
                      </Link>
                      <Link to="/device">
                        <Nav.Item className={cn("p-3", s.nav_item, { [s.nav_link_active]: location.pathname === "/device" })}>
                          <h6 className="m-0 animate__animated animate__fadeInDown">Мои устройства</h6>
                        </Nav.Item>
                      </Link>
                      <Link to="/installation">
                        <Nav.Item className={cn("p-3", s.nav_item, { [s.nav_link_active]: location.pathname === "/installation" })}>
                          <h6 className="m-0 animate__animated animate__fadeInDown">Инсталяции</h6>
                        </Nav.Item>
                      </Link>
                      <Link to="/downloadlog">
                        <Nav.Item className={cn("p-3", s.nav_item, { [s.nav_link_active]: location.pathname === "/downloadlog" })}>
                          <h6 className="m-0 animate__animated animate__fadeInDown">Журнал загрузок</h6>
                        </Nav.Item>
                      </Link>
                    </>
                  )}
                </Col>
              </Row>
            </Nav>
          </Container>
        </div>
      </header>

      {isLoginShow && (
        <LoginModal
          handleClose={() => {
            setIsLoginShow(false);
          }}
        />
      )}
    </>
  );
};
