import React from "react";
import { Container } from "react-bootstrap";

const About: React.FC = () => {
  return (
    <Container className="text-full-width text-black my-5 fs-5">
      <h1 className="fw-bold mb-4">О нас</h1>
      <hr />
    </Container>
  );
};

export default About;
