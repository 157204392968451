import React from "react";
import { Container } from "react-bootstrap";

import cn from "classnames";
import s from "./Terms.module.scss";

const Contacts: React.FC = () => {
  return (
    <Container className={cn("text-full-width t-b my-5 fs-6", s.terms)}>
      <h1 className="">Правила пользования</h1>
      <p>ред. от 25 ноября 2022</p>
      <p>
        Настоящим Пользовательским соглашением регулируются правоотношения между Администрацией Сайта с доменным именем https://neg24.ru/ и Пользователем Сайта по использованию Сайта и размещенных на
        нем материалов.
      </p>
      <p>
        Кроме настоящего Пользовательского соглашения отношения между Администрацией Сайта и Пользователем применительно к конкретным правоотношениям регулируются также иными документами (Публичная
        оферта, Политика конфиденциальности).
      </p>
      <p>Администрация Сайта просит внимательно ознакомиться с Пользовательским соглашением.</p>
      <h3>1. ОСНОВНЫЕ ПОНЯТИЯ</h3>
      <p>
        1.1. <b>Зарегистрированный пользователь</b> — Пользователь, который прошел процедуру Регистрации на Сайте в установленном порядке.
      </p>
      <p>
        1.2. <b>Исключительное право</b> – право использовать Сайт, размещенные на Сайте материалы по своему усмотрению любым не противоречащим закону способом (часть 1 статьи 1229 Гражданского
        кодекса Российской Федерации).
      </p>
      <p>
        1.3. <b>Материалы</b> — любые размещенные на Сайте или содержащиеся в рассылке Произведения.
      </p>
      <p>
        1.4. <b>Произведение</b> – Сайт, как составное произведение, а также размещаемые на Сайте графические изображения, статьи, аудиовизуальные произведения (видеоуроки, видеозаписи вебинаров)
        интеллект-карты, чек-листы и иные произведения, размещенные на Сайте.
      </p>
      <p>
        1.5. <b>Личный кабинет</b> — закрытый персональный раздел Зарегистрированного пользователя на Сайте, с помощью которого Зарегистрированный Пользователь имеет доступ к оплаченным Материалам.
      </p>
      <p>
        1.6. <b>Пользователь</b> – любое дееспособное физическое лицо, прошедшее процедуру Регистрацию на Сайте или не прошедшее, и использующее Сайт в собственных интересах или выступающее от имени
        представляемого им юридического лица.
      </p>
      <p>
        1.7. <b>Пользовательское соглашение</b> – настоящий документ со всеми изменениями и дополнениями, расположенный в сети интернет по адресу https://neg24.ru/terms.
      </p>
      <p>
        1.8. <b>Регистрация на Сайте</b> — совокупность действий Пользователя в соответствии с Пользовательским соглашением в целях формирования Личного кабинета.
      </p>
      <p>
        1.9. <b>Сайт</b> — Сайты, размещенный в сети интернет под доменными именами https://neg24.ru/, включая поддомены и Лендинги.
      </p>
      <p>
        1.10. <b>Товары</b> — цифровые (электронные) товары (электронные книги, записи вебинаров, мастер-классов, тренингов), которые продаются дистанционно на сайтах с доменными именами
        https://neg24.ru/, включая поддомены и Лендинги.
      </p>
      <p>
        1.11. <b>Услуги</b> — услуги по обучению, а также информационно-консультационные услуги, предоставляемые Администрацией Сайта Пользователям, Зарегистрированным пользователям, информация о
        которых указана на сайтах https://neg24.ru/, включая поддомены, а также Лендинги.
      </p>
      <p>
        1.12. <b>Рассылка</b> — автоматизированная отправка электронных писем на электронный адрес (email) или на номер мобильного телефона Пользователя с адресов домена https://neg24.ru/.
      </p>
      <p>
        1.13. <b>Подписка</b> — добровольная передача Пользователем email-адреса и других персональных данных для получения Рассылки.
      </p>
      <p>
        1.14. <b>Отписка</b> — отказ Пользователя от получения Рассылки, в результате чего он перестает получать электронные письма Рассылки.
      </p>
      <p>
        1.15. <b>Персональные данные</b> — любая информация, относящаяся прямо или косвенно к Пользователю (субъекту персональных данных), добровольно указанная им при Подписке или Регистрации на
        Сайте для получения Услуг. Данные хранятся в базе данных Администрации Сайта (с использованием сервисов и программ, обеспечивающих надежную защиту от несанкционированного доступа третьих лиц)
        и подлежат использованию исключительно в соответствии с настоящим документом, Политикой конфиденциальности и действующим законодательством РФ.
      </p>
      <p>Все остальные термины и определения, встречающиеся в тексте Договора, толкуются Сторонами в соответствии с действующим законодательством Российской Федерации.</p>
      <h3>2. ОБЩИЕ ПОЛОЖЕНИЯ</h3>
      <p>2.1. Использование Сайта и размещенных на нем Материалов регулируется действующим российским законодательством и положениями настоящего Пользовательского соглашения.</p>
      <p>
        2.2. Пользовательское соглашение является публичной офертой в соответствии с частью 2 статьи 437 Гражданского кодекса Российской Федерации. Пользователь присоединяется к настоящему
        Пользовательскому соглашению с момента доступа к Сайту и его Материалам, и действует без ограничения срока.
      </p>
      <p>
        2.3. Настоящее Пользовательское соглашение опубликовано на https://neg24.ru/ и вступает в силу с момента публикации на Сайте и действует в течение неопределённого срока или до публикации
        следующей редакции Пользовательского соглашения.
      </p>
      <p>
        2.4. Пользование Сайтом возможно только в соответствии с условиями, которые установлены в Пользовательском соглашении, Публичной оферте и Политике конфиденциальности. Пользователь обязуется
        прекратить использование Сайта в случае несогласия с какими-либо условиями настоящего Пользовательского соглашения и других документов.
      </p>
      <h3>3. ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЯ</h3>
      <p>3.1. Пользователь вправе:</p>
      <ul>
        <li>
          <p>• использовать Сайт для личных нужд любыми способами в соответствии с российским законодательством и настоящим Пользовательским соглашением.</p>
        </li>
        <li>
          <p>• Пользователю запрещается использовать Сайт с целью получения коммерческой выгоды;</p>
        </li>
        <li>
          <p>• самостоятельно изучать размещенные на Сайте Произведения и Материалы и использовать их для личных нужд; оставлять комментарии и сообщения на Сайте и/или на Форуме;</p>
        </li>
        <li>
          <p>• оставляет за собой право не отвечать на вопросы Пользователей;</p>
        </li>
        <li>
          <p>
            • предоставлять адрес своей электронной почты в подписных формах на Сайте, давая при этом согласие на получение писем рассылки Администрации Сайта. в любой момент отписаться от Рассылки
            полностью либо частично с помощью клика на соответствующей ссылке в конце каждого письма, получаемого с помощью сервиса Рассылки.
          </p>
        </li>
      </ul>
      <p>3.2. Пользователь обязан:</p>
      <ul>
        <li>
          <p>• использовать Сайт таким образом, чтобы не нарушать российское законодательство и настоящее Пользовательское соглашение;</p>
        </li>
        <li>
          <p>• соблюдать права и свободы третьих лиц;</p>
        </li>
        <li>
          <p>• не предпринимать каких-либо действий, которые могут привести к непропорционально большой нагрузке на Сайт;</p>
        </li>
        <li>
          <p>
            • соблюдать авторские права Администрации Сайта, а также третьих лиц, Произведения которых размещены на Сайте. предоставить при Регистрации на Сайте актуальную информацию, которая может
            быть запрошена от него регистрационными формами Сайта (регистрационные данные).
          </p>
        </li>
        <li>
          <p>
            • не предоставлять доступ другим Пользователям к собственным Персональным данным Пользователя и/или Личному кабинету Пользователя, а также к приобретенным Материалам и/или Произведениям.
            нести полную ответственность за любые действия, совершенные Пользователем с использованием его Личного кабинета, а также за любые последствия, которые могло повлечь или повлекло подобное
            его использование.
          </p>
        </li>
        <li>
          <p>
            • оформляя Подписку на Рассылку, либо Регистрируясь на сайте с целью получения Услуг на Сайте и оплачивая их, Пользователь подтверждает, что дает свое согласие на получение на указанный им
            адрес электронной почты рекламной и иной информации от Администрации Сайтаs
          </p>
        </li>
      </ul>
      <p>3.3. Пользователь несет в соответствии с российским законодательством полную ответственность за собственные действия и бездействие при использовании Сайта.</p>
      <p>3.4. Администрация Сайта имеет право:</p>
      <ul>
        <li>
          <p>• изменять условия настоящего Пользовательского соглашения;</p>
        </li>
        <li>
          <p>
            • устанавливать в любое время любые ограничения в использовании Сайта по своему усмотрению;производить профилактические работы на Сайте с временным приостановлением работы Сайта без
            уведомления Пользователей;
          </p>
        </li>
        <li>
          <p>
            • собирать на Сайте через формы подписки и регистрационные формы электронные адреса Пользователей, которые используются только для целей рассылки новостей Сайта, а также другой информации
            Администрации Сайта.
          </p>
        </li>
        <li>
          <p>
            • осуществлять Рассылку новостей Сайта и информацию Администрации Сайта по адресам электронной почты, предоставленных Пользователями через формы подписки или формы для регистрации на
            Сайте;
          </p>
        </li>
        <li>
          <p>
            • модерировать материалы, размещаемые Пользователями на Сайте, а также удалять любые материалы, комментарии, сообщения, нарушающие правила настоящего Пользовательского соглашения и
            действующего законодательства, без объяснения причин, а также приостанавливать, ограничивать или прекращать доступ Пользователя ко всем или к любому из разделов Сайта с предварительным
            уведомлением или без такового;
          </p>
        </li>
        <li>
          <p>
            • предоставлять через Сайт платные Услуги, осуществлять продажу Товаров дистанционным способом, а также рассылать информацию об Услугах, Товарах, акциях, скидках по адресам электронной
            почты, предоставленных Пользователями через формы подписки или формы для регистрации на Сайте;
          </p>
        </li>
        <li>
          <p>
            • в любое время изменять оформление Сайта, его содержание, Материалы, изменять или дополнять используемые скрипты, программное обеспечение и другие объекты, используемые или хранящиеся на
            Сайте, любые серверные приложения в любое время с предварительным уведомлением или без такового;
          </p>
        </li>
        <li>
          <p>
            • в случае нарушения Пользователем любого положения настоящего Пользовательского соглашения, содержащего условия пользования Сайтом (п. 3.2., 3.5. 5.6, 5.7.), Администрация Сайта вправе
            временно прекратить доступ к использованию Материалов и функционалом Сайта до момента устранения нарушения, ограничить доступ к Личному кабинету, а также к приобретенным Услугам и Товарам,
            либо прекратить доступ к использованию функционала Сайта без предоставления времени на исправления нарушений, вплоть до удаления Личного кабинета Пользователя. При этом денежные средства,
            оплаченные за предоставленные те или иные Услуги, возврату не подлежат;
          </p>
        </li>
        <li>
          <p>
            • осуществлять техническую поддержку Пользователя по вопросам и в следующем порядке: Техническая поддержка в форме электронной консультации предоставляется только по официальному запросу
            Пользователя, направленному Администрации Сайта по адресу: support@neg24.ru. Консультация специалиста может быть предоставлена по следующим вопросам: регистрация и проблемы при ее
            прохождении, функционирование Сайта и его сервисов. Не предоставляются консультации по вопросам настройки оборудования, программного обеспечения или Интернет — доступа Пользователя или
            иных третьих лиц, а также по другим вопросам, не имеющим отношения к работе Сайта и его сервисов.
          </p>
        </li>
      </ul>
      <p>3.5. Пользователю запрещается:</p>
      <ul>
        <li>
          <p>
            • предпринимать какие-либо действия, которые направлены на нарушение нормальной работы Сайта; подключать и использовать любое программное обеспечения, предназначенное для взлома или
            агрегации личных данных других Пользователей, включая логины, пароли и т. д., а также для проведения автоматической массовой рассылки какого бы то ни было содержания, использовать любые
            автоматические или автоматизированные средства для сбора информации, размещенной на Сайте.
          </p>
        </li>
        <li>
          <p>
            • осуществлять действия, направленные на введение кого-либо в заблуждение путём присвоения себе чужого имени и намеренной публикации, отправки сообщений или другого способа использования
            присвоенного имени противозаконно, для умышленного нанесения убытков кому-либо или в любых корыстных целях;
          </p>
        </li>
        <li>
          <p>
            • копировать программный код Сайта, попытки нарушить программный код Сайта, иные попытки нанести вред программному коду Сайта, попытки внедрять исполняемый код на стороне Пользователя
            (клиентские скрипты: java-script, visual basic-script и т.п.), любые внедряемые объекты (java-апплеты, flash и т.п.), использовать frame и iframe, каскадные таблицы стилей,
            предопределяющие, используемые на Сайте, а также html-код, нарушающий оригинальный дизайн страницы, или совершения иных противозаконных действий (рассылка спама, рассылка незаконных
            материалов и прочее). Администрация Сайта вправе отказать Пользователю в использовании Сайта, а также предпринять иные возможные меры;
          </p>
        </li>
        <li>
          <p>
            • осуществлять сбор персональных данных других Пользователей с целью их последующей обработки, т.е. совершения действий (операций) с персональными данными, включая их сбор, систематизацию,
            накопление, хранение, уточнение (обновление, изменение), использование, распространение (в том числе передачу), обезличивание, блокирование и уничтожение;
          </p>
        </li>
        <li>
          <p>• регистрироваться в качестве Пользователя от имени или вместо другого лица, а также указывать выдуманные имя и фамилию, то есть создавать «фальшивый аккаунт».;</p>
        </li>
        <li>
          <p>
            • использовать Сайт в каких-либо коммерческих целях без предварительного письменного разрешения Администрации Сайта; воспроизводить, дублировать, копировать, продавать, загружать,
            публиковать, транслировать, передавать, предоставлять в общественное пользование, или другим образом использовать содержание Сайта, Произведений и Материалов, за исключением личных,
            некоммерческих целей. Любое другое использование содержания Сайта возможно лишь с предварительного письменного согласия Администрации Сайта.
          </p>
        </li>
      </ul>
      <p>
        3.6. Администрация Сайта не гарантирует, что сервер, на котором размещен Сайт, свободен от ошибок и компьютерных вирусов. Администрация Сайта не несет какой-либо ответственности, если
        использование Сайта повлекло за собой утрату данных или порчу оборудования Пользователя.
      </p>
      <p>
        3.7. Сайт содержит ссылки на другие интернет-Сайты. Администрация Сайта не несет какой-либо ответственности за доступность Сайтов, содержащуюся на них информацию, а также любые негативные
        последствия, связанные с посещением и использованием Пользователем других Сайтов, ссылки на которые размещены на Сайте.
      </p>
      <h3>4. УСЛУГИ САЙТА</h3>
      <p>4.1. Администрация Сайта предоставляет Пользователям безвозмездную услугу по доступу к открытой информации, размещенной на Сайте и не требующей дополнительных уровней доступа.</p>
      <p>
        4.2. Администрация Сайта предоставляет платные услуги по обучению на дистанционных онлайн-курсов (тренингов, практикумов и т. д.), вебинаров, по получению доступа к записям вебинаров и курсов,
        а также платные информационно-консультационные услуги, услуги контент-маркетинга, по написанию и аудиту текстов.
      </p>
      <p>4.3. Порядок оказания Услуг и продажи Товаров регулируется Публичной офертой.</p> <h3>6. РЕГИСТРАЦИЯ</h3>
      <p>6.1. Для получения доступа к Услугам Пользователь должен зарегистрироваться на Сайте в порядке, предусмотренном настоящим Пользовательским соглашением.</p>
      <p>6.2. Пользователь проходит на Сайте процедуру Регистрации путем заполнения формы на странице https://neg24.ru/.</p>
      <p>6.3. При Регистрации Пользователь обязан использовать свой действительный адрес электронной почты и создать надежный уникальный пароль.</p>
      <p>
        6.4. При Регистрации на Сайте Пользователь выражает свое согласие: c условиями Пользовательского соглашения; с Политикой конфиденциальности, а также дает согласие на обработку персональных
        данных, доступных Администрации Сайта.
      </p>
      <p>6.5. Для каждого Пользователя, регистрирующегося на Сайте, создается уникальная учетная запись и Личный кабинет для доступа к Материалам, Услугам, Товарам.</p>
      <p>6.6. Доступ Зарегистрированного пользователя к Материалам, Услугам, Товарам осуществляется после их оплаты.</p>
      <p>6.7. Зарегистрированный пользователем осуществляется вход в Личный кабинет путем авторизации на странице https://neg24.ru/.</p>
      <p>6.8. При невозможности совершения авторизации Зарегистрированный пользователь вправе обратиться к Администрации Сайта по электронной почте support@neg24.ru.</p>
      <p>6.9. Способы регистрации, авторизации, восстановления доступа к Личному кабинету могут быть изменены, отменены или дополнены Администрацией Сайта в одностороннем порядке.</p>
      <p>6.10. Зарегистрированный пользователь обязуется незамедлительно информировать Администрацию Сайта о любом несанкционированном использовании его Личного кабинета третьими лицами.</p>
      <h3>7. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ</h3>
      <p>
        7.1. Сайт, в том числе Сайт как программа ЭВМ, его название, логотип, элементы его дизайна, оформления и общий внешний вид Сайта являются интеллектуальной собственностью Администрации Сайта.
        Любое использование названия Сайта, логотипа, элементов его дизайна запрещено.
      </p>
      <p>7.2. Любое использование названия Сайта запрещено.</p>
      <p>7.3. Статьи, графические изображения и иные Произведения, размещенные на Сайте, принадлежат Администрации Сайта.</p>
      <p>
        7.4. Использование размещенных на Сайте Произведений, воспроизведение и распространение их любыми способами, в том числе в интернете, публичный показ, доведение до всеобщего сведения,
        копирование (полное и частичное), перевод, переделка, запрещено, за исключением случаев, когда Администрация Сайта дает прямое разрешение на такие действия, а также в случаях, предусмотренных
        в пунктах 7.6.-7.8. настоящего Пользовательского соглашения.
      </p>
      <p>7.5. Для получения согласия на использование размещенных на Сайте Произведений необходимо обратиться к Администрации Сайта по электронной почте info@neg24.ru.</p>
      <p>
        7.6. Перепечатка статей, размещенных на настоящем Сайте, разрешается Администрацией Сайта с обязательной ссылкой на первоисточник в виде активной, не закрытой от индексирования поисковыми
        системами ссылки на статью.
      </p>
      <p>
        7.7. Цитирование статей в научных, полемических, критических, информационных, учебных целях в объеме, оправданном целью цитирования, возможно с соблюдением условий, указанных в пункте 7.5. и
        7.8. Пользовательского соглашения.
      </p>
      7.8. При цитировании статей с Сайта в интернете обязательна ссылка на первоисточник (подпункт 1 пункта 1 статьи 1274 Гражданского кодекса Российской Федерации) в виде активной, не закрытой от
      индексирования поисковыми системами ссылки на статью. При цитировании статей с Сайта в книгах, печатных СМИ (газетах, журналах и других печатных СМИ), а также в другой печатной продукции
      (буклетах, проспектах и т. д.) обязательна ссылка на Сайт. 7.9. За нарушение авторских и исключительных прав предусмотрена уголовная и гражданская ответственность. Любые нарушения преследуются
      Администрацией Сайта в судебном порядке.
      <h3>8. ПОЧТОВАЯ РАССЫЛКА</h3>
      <p>8.1. Пользователь предоставляет Администрации Сайта адрес электронной почты через форму подписки, размещенную на Сайте, а также через форму заказа при приобретении Услуг и Товаров.</p>
      <p>
        8.2. Адрес электронный почты, полученный от Пользователя в порядке, предусмотренным в пункте 8.1, используется только для рассылки по электронной почте новостей Сайта, другой полезной
        информации от Администрации Сайта или партнеров Администрации Сайта.
      </p>
      <p>8.3. Предоставляя адрес своей электронной почты в порядке, предусмотренным в пункте 8.1, Пользователь дает согласие на получение писем рассылки от Администрации Сайта.</p>
      <p>
        8.4. Получение писем рассылки является добровольным. Пользователь может в любое время отказаться от получения писем, кликнув на ссылку для отписки, которая присутствует в каждом получаемом
        письме. В этом случае адрес электронной почты Пользователя будет автоматически удален из базы рассылки.
      </p>
      <p>8.5. Адрес электронной почты Пользователя хранится на защищенных серверах сервиса Sendsay.ru и используются в соответствии с политикой конфиденциальности данного почтового сервиса.</p>
      <p>
        8.6. Исключительное право на Материалы, содержащиеся в Рассылке, в том числе товарные знаки, логотипы, коммерческие наименования, иные способы индивидуализации, любые тексты, изображения, иные
        объекты авторского права, размещенные на Сайте, являются собственностью Сайта, права на которые охраняются законом принадлежат Администрации Сайта. Любое использование указанных материалов без
        согласия Администрации Сайта запрещено.
      </p>
      <h3>11. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h3>
      <p>11.1. Пользовательское соглашение вступает в силу с момента опубликования и действует до момента его отзыва Администрацией Сайта.</p>
      <p>
        11.2. Администрация Сайта имеет право внести любые изменения и дополнения в условия Пользовательского соглашения или отозвать Пользовательское соглашение в любой момент по своему усмотрению.
      </p>{" "}
      <p>
        11.3. Настоящее Пользовательское соглашение регулируется и толкуется в соответствии с законодательством Российской Федерации. Вопросы, не урегулированные данным документов, подлежат разрешению
        в соответствии с законодательством Российской Федерации.
      </p>{" "}
      <p>
        11.4. Если по тем или иным причинам одно или несколько положений настоящего Пользовательского соглашения будут признаны недействительными или не имеющими юридической силы, это не оказывает
        влияния на действительность или применимость остальных положений.
      </p>
    </Container>
  );
};

export default Contacts;
