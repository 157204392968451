import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import PeopleOrder from "./BuyerOrder/PeopleOrder";
import OrganizationOrder from "./BuyerOrder/OrganizationOrder";
import classNames from "classnames";
import { useAppSelector } from "../../redux/hooks";
import { selectCurrency } from "../../redux/currency/selectors";
import { selectCart } from "../../redux/cart/selectors";

import s from "./Cart.module.scss";

const CartOrder: React.FC = () => {
  enum BuyerType {
    people,
    partner,
  }
  const currency = useAppSelector(selectCurrency);
  const [buyertype, setBuyer] = React.useState<BuyerType>(BuyerType.people);
  const { totalPrice, items } = useAppSelector(selectCart);
  const totalCount = items.reduce((sum: number, item: any) => sum + item.cnt, 0);

  const changeBuyerHandler = (event: React.MouseEvent<HTMLButtonElement>, p: BuyerType) => {
    event.stopPropagation();
    setBuyer(p);
    return false;
  };

  return (
    <Container className="order-container text-dark t-0" style={{ backgroundColor: "white" }}>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Row className="text-center align-items-center my-4">
            <Col xs={12} sm={5} md={4} lg={3} xl={4}>
              <Link to="/">
                <img src="/images/logo_2.png" className="logo w-100" alt="LOGO"></img>
              </Link>
            </Col>
            <Col xs={12} sm={5} md={4} lg={3} xl={4}>
              <div className="align-items-center">
                <p className="mb-0">Оформляем {totalCount} товар(-ов, -а)</p>
                <p className="mb-0">
                  на сумму {totalPrice.toLocaleString()} {currency.nameshort}
                </p>
              </div>
            </Col>
          </Row>
          <hr />
          <h2 className="mt-5">Данные покупателя</h2>
          <Row className="mt-3 mb-5 align-items-center" role="group" aria-label="Базовая группа переключателей радио">
            <Col
              xxs={6}
              xs={6}
              md={6}
              lg={6}
              xl={6}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => changeBuyerHandler(event, BuyerType.people)}
              className={classNames(
                "py-2 text-center rounded-0 ",
                s.btn_buyer,
                {
                  "border-3 border-top border-end border-start rounded-top": buyertype === BuyerType.people,
                },
                {
                  "border-3 border-bottom": buyertype === BuyerType.partner,
                }
              )}
            >
              <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" defaultChecked />
              <label htmlFor="btnradio1">Физическое лицо</label>
            </Col>

            <Col
              xxs={6}
              xs={6}
              md={6}
              lg={6}
              xl={6}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => changeBuyerHandler(event, BuyerType.partner)}
              className={classNames(
                "py-2 text-center rounded-0",
                s.btn_buyer,
                {
                  "border-3 border-top border-start border-end rounded-top": buyertype === BuyerType.partner,
                },
                {
                  "border-3 border-bottom": buyertype === BuyerType.people,
                }
              )}
            >
              <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" />
              <label htmlFor="btn-check">Юридическое лицо</label>
            </Col>
          </Row>
          <div>
            {buyertype === BuyerType.people && <PeopleOrder />}
            {buyertype === BuyerType.partner && <OrganizationOrder />}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CartOrder;
