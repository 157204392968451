import React from "react";
import dateFormat from "dateformat";
import { Card } from "react-bootstrap";

export type ClientInstallItemProps = {
  clientinstallationid: string;
  installationdate: Date;
  productid: string;
  productname: string;
  clientsysinfo: string;
};

const ClientInstallItem: React.FC<ClientInstallItemProps> = ({ installationdate, productname }) => {
  return (
    <Card className="py-3 px-5 mb-5">
      <p>Дата: {dateFormat(installationdate, " d mmmm, yyyy, HH:MM")}</p>
      <p>Продукт: {productname}</p>
      {/* <p>клиентская информация: {clientsysinfo}</p> */}
    </Card>
  );
};

export default ClientInstallItem;
