const config = {
  //API_URL: 'http://localhost:3000/api',
  //API_URL: "http://localhost:62222/api",
  //API_URL: 'https://dzuyba.paut.ru/api',
  //  API_URL: 'http://localhost:8000/api',
  API_URL: 'https://neg24.ru/api',
  SHOP: "itasset",
};

export default config;
