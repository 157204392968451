import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";
import ClientService from "../../services/ClientService";

enum LicSchemeMode {
  OnExecute,
  OnConnect,
}

enum LicSchemeType {
  Computer,
  User,
  Concurent,
}

enum LicSchemeStatus {
  NotActive,
  Active,
  HardwareFail,
}

type ClientLicSchemeItemProps = {
  clientlicschemeid: string;
  recorddatecreate: Date;
  licmode: LicSchemeMode;
  lictype: LicSchemeType;
  datefinish: Date;
  daysfrombuy: number;
  daysfromactivation: number;
  launchcount: number;
  connectcount: number;
  productid: string;
  productname: string;
  dateconstraint: number;
  clientdeviceid: string;
  clientdevicename: string;
  activationdate: Date;
  name: string;
  status: LicSchemeStatus;
};

function licSchemeModeToString(licSchemeMode: LicSchemeMode) {
  let res = "";
  switch (licSchemeMode) {
    case LicSchemeMode.OnConnect:
      res = "На подключение";
      break;
    case LicSchemeMode.OnExecute:
      res = "На запуск";
      break;
  }
  return res;
}

function licSchemeTypeToString(licSchemeType: LicSchemeType) {
  let res = "";
  switch (licSchemeType) {
    case LicSchemeType.Computer:
      res = "На компьютер";
      break;
    case LicSchemeType.Concurent:
      res = "Конкурентная";
      break;
    case LicSchemeType.User:
      res = "На пользователя";
      break;
  }
  return res;
}

function licSchemeStatusToString(licSchemeType: LicSchemeStatus) {
  let res = "";
  switch (licSchemeType) {
    case LicSchemeStatus.NotActive:
      res = "Не активная";
      break;
    case LicSchemeStatus.Active:
      res = "Активная";
      break;
    case LicSchemeStatus.HardwareFail:
      res = "Не соответствие Device Hash";
      break;
  }
  return res;
}

function licSchemeStatusToColor(licSchemeTypeColor: LicSchemeStatus) {
  let res = "";
  switch (licSchemeTypeColor) {
    case LicSchemeStatus.NotActive:
      res = "red";
      break;
    case LicSchemeStatus.Active:
      res = "green";
      break;
    case LicSchemeStatus.HardwareFail:
      res = "#3e4652";
      break;
  }
  return res;
}

const ClientLicSchemeItem: React.FC<ClientLicSchemeItemProps> = (licScheme) => {
  const [licSchemeSelf, setLicScheme] = React.useState<ClientLicSchemeItemProps>({ ...licScheme });

  const clearDevice = () => {
    ClientService.licSchemeDeviceClear(licSchemeSelf.clientlicschemeid)
      .then((res) => {
        setLicScheme({ ...licSchemeSelf, clientdeviceid: "", clientdevicename: "" });
        // console.log(res);
      })
      .catch((e: any) => {
        if (e.response) {
          console.error(e.response.data);
          alert(e.response.data);
        } else {
          console.error(e.message);
          alert(e.message);
        }
      });
  };

  return (
    <Card className=" py-3 px-5 mb-5">
      <Row className="justify-content-between align-items-center">
        <Col className="fs-5" xs={12} sm={12} md={6} lg={6} xl={6}>
          <p>
            Статус: <b style={{ color: licSchemeStatusToColor(licSchemeSelf.status) }}>{licSchemeStatusToString(licSchemeSelf.status)}</b>
          </p>
          {licSchemeSelf.status !== LicSchemeStatus.NotActive && <p>Дата активации: {dateFormat(licSchemeSelf.recorddatecreate, " d mmmm, yyyy, HH:MM")}</p>}
          <p>Лицензия: {licSchemeSelf.name}</p>
          <p>Продукт: {licSchemeSelf.productname}</p>
          <p>Режим: {licSchemeModeToString(licSchemeSelf.licmode)}</p>
          <p className="mb-0">Тип: {licSchemeTypeToString(licSchemeSelf.lictype)}</p>
        </Col>
        <Col xs={12} sm={12} md={6} lg={4} xl={4} className="text-center device-column" style={{ color: "#3e4652" }}>
          {licSchemeSelf.clientdeviceid && (
            <>
              {licScheme.status === LicSchemeStatus.HardwareFail && (
                <Link to="/">
                  <img src="/images/no.png" className="w-50" alt="device" />
                </Link>
              )}
              {licScheme.status !== LicSchemeStatus.HardwareFail && (
                <Link to="/">
                  <img src="/images/device-monoblock.png" className="w-50" alt="device" />
                </Link>
              )}
              <p>
                Привязана к устройству: <b>{licSchemeSelf.clientdevicename}</b>
              </p>

              <Button className="style-btn-action w-100" size="lg" onClick={clearDevice}>
                Отвязать
              </Button>
            </>
          )}
          {!licSchemeSelf.clientdeviceid && <p className="mb-0">Лицензия не привязана ни к одному устройству</p>}
        </Col>
      </Row>
    </Card>
  );
};

export default ClientLicSchemeItem;
