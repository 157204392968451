import React from "react";
// import { useDispatch } from 'react-redux';
import { cartAdd, cartDec, cartDel } from "../../redux/cart/asyncActions";
import { selectCurrency } from "../../redux/currency/selectors";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import nomenclatureNoImg from "../../assets/img/nomenclature_no_image.png";

import config from "../../config";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import minus from "../../assets/img/svg/minus.svg";
import plus from "../../assets/img/svg/plus.svg";
import minusmuted from "../../assets/img/svg/minusmuted.svg";
import cartclearitem from "../../assets/img/svg/cartclearitem.svg";

import cn from "classnames";
import s from "./Cart.module.scss";

type CartProps = {
  nomenclatureid: string;
  name: string;
  price: number;
  cnt: number;
  images: any[];
};

const CartItem: React.FC<CartProps> = ({ nomenclatureid, name, price, cnt, images }) => {
  const dispatch = useAppDispatch();
  const currency = useAppSelector(selectCurrency);
  // console.log({ nomenclatureid, name, price, cnt, images });
  // console.log('CartItem price', price);
  // console.log('CartItem count', cnt);

  const onClickPlus = () => {
    dispatch(cartAdd(nomenclatureid));
  };

  const onClickMinus = () => {
    dispatch(cartDec(nomenclatureid));
  };

  const onClickRemove = () => {
    if (window.confirm("Вы действительно хотите удалить товар?")) {
      dispatch(cartDel(nomenclatureid));
    }
  };

  return (
    <>
      <Row className="align-items-center justify-content-between text-center mb-3">
        <div className="col-12 d-sm-none mb-2 text-start">
          <Link key={nomenclatureid} to={`/nomenclature/${nomenclatureid}`}>
            <span className={cn("fs-4 fw-bold", s.item_name)}>{name}</span>
          </Link>
        </div>
        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12">
          <Link key={nomenclatureid} to={`/nomenclature/${nomenclatureid}`}>
            <img className="w-75 mw-100" src={images.length > 0 ? `${config.API_URL}/nomenclature_image/` + images[0].imageid : nomenclatureNoImg} alt={name} />
          </Link>
        </div>
        <div className="d-none d-sm-block col-xl-5 col-lg-4 col-md-4 col-sm-4 col-12 p-3 text-start">
          <Link key={nomenclatureid} to={`/nomenclature/${nomenclatureid}`}>
            <span className={s.item_name}>{name}</span>
          </Link>
        </div>
        <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-4 prod-descr d-flex justify-content-center align-items-center text-center">
          {cnt > 1 && (
            <span className="minus" onClick={onClickMinus}>
              <img src={minus} alt="minus" />
            </span>
          )}
          {cnt === 1 && (
            <span className="minus">
              <img src={minusmuted} alt="minus" />
            </span>
          )}
          <input type="text" disabled name="name" className="count w-25 m-1 text-center rounded-3" style={{ border: "#7c7c7c 2px solid" }} value={cnt} />
          <span className="plus " onClick={onClickPlus}>
            <img src={plus} alt="" />
          </span>
        </div>
        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4 p-0 text-end">
          <span className="item-cost fw-bold text-b">
            {(price * cnt).toLocaleString()} {currency.nameshort}
          </span>
        </div>
        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 p-0">
          <span className="delete-item-btn" onClick={onClickRemove}>
            <img src={cartclearitem} alt="" />
          </span>
        </div>
        <hr className="text-dark" />
      </Row>
    </>
  );
};

export default CartItem;
