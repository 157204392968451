import React from "react";
import { Container, Spinner } from "react-bootstrap";
import ClientService, { ClientDownload } from "../../services/ClientService";
import ClientDownloadLogItem from "./ClientDownloadLogItem";
import { Pagination } from "../../components/Pagination";
import EmptyBox from "../../components/EmptyBox";

const ClientDownloadLogList: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [items, setItems] = React.useState([] as ClientDownload[]);
  const [error, setError] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalcount, setTotalcount] = React.useState(0);
  const limit = 50;
  const pageCount = Math.floor(totalcount / limit) + (totalcount % limit ? 1 : 0);

  const onChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const effectFunc = () => {
    setLoading(true);
    setError(undefined);
    ClientService.downloadGetAll(currentPage, limit)
      .then((res) => {
        setItems(res.data.items);
        setTotalcount(res.data.totalcount);
      })
      .catch((e: any) => {
        if (e.response) {
          console.log(e.response.data);
          setError(e.response.data);
        } else {
          console.log("Error", e.message);
          setError(e.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  React.useEffect(effectFunc, [currentPage]);
  if (loading)
    return (
      <Container className="m-5 text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">загрузка...</span>
        </Spinner>
      </Container>
    );

  if (error) return <Container className="text-center">{error}</Container>;
  if (items.length === 0) return <EmptyBox />;

  return (
    <Container className="my-5">
      <div className="d-none d-md-block">
        <h1>Журнал загрузок</h1>
        <hr className="text-dark" />
      </div>
      {items.map((item: any) => (
        <ClientDownloadLogItem key={item.clientdownloadid} {...item} />
      ))}

      {pageCount > 1 && (
        <Container className="p-4">
          <Pagination currentPage={currentPage} onChangePage={onChangePage} pageCount={pageCount} />
        </Container>
      )}
    </Container>
  );
};

export default ClientDownloadLogList;
