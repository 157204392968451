import React from "react";
import { Button, Col, Container, Spinner } from "react-bootstrap";
import ShopService, { Organization } from "../../services/ShopService";
import OrganizationControl, { OrganizationControlState } from "./OrganizationControl";
import OrganizationItem from "./OrganizationItem";

type OrganizationEx = Organization & { hasinvoice: boolean };

const OrganizationList: React.FC = () => {
  enum Status {
    list,
    add,
  }

  const [loading, setLoading] = React.useState(true);
  const [items, setItems] = React.useState([] as OrganizationEx[]);
  const [error, setError] = React.useState();
  const [status, setStatus] = React.useState<Status>(Status.list);

  const onSaveCallback = (organization: Organization) => {
    setItems([...items, { ...organization, hasinvoice: false }]);
    setStatus(Status.list);
  };

  const onCancelCallback = () => {
    setStatus(Status.list);
  };

  const onDeleteCallback = (organizationid: string) => {
    const items_new = items.filter((item: any) => item.organizationid !== organizationid);
    setItems(items_new);
  };

  const effectFunc = () => {
    setLoading(true);
    setError(undefined);
    ShopService.organizationGet()
      .then((res: any) => {
        setItems(res.data.items);
      })
      .catch((e: any) => {
        if (e.response) {
          setError(e.response.data);
        } else {
          console.log("Error", e.message);
          setError(e.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  React.useEffect(effectFunc, []);

  if (loading)
    return (
      <Container className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">загрузка...</span>
        </Spinner>
      </Container>
    );

  if (error) return <Container className="text-center">{error}</Container>;

  return (
    <Container className="my-5 justify-contet-start">
      <div className="d-none d-md-block">
        <h1>Мои юр. лица</h1>
        <hr className="text-dark" />
      </div>
      <Col xl={6}>
        {status !== Status.add && (
          <>
            <Button variant="outline-secondary" className="w-100 my-3 border" onClick={() => setStatus(Status.add)}>
              + Добавить новое юридическое лицо
            </Button>
            {items.map((item: OrganizationEx) => (
              <OrganizationItem
                key={item.organizationid}
                organization={{ ...item } as Organization}
                hasinvoice={item.hasinvoice}
                deleteCallBack={() => {
                  onDeleteCallback(item.organizationid);
                }}
              />
            ))}
          </>
        )}

        {status === Status.add && (
          <OrganizationControl
            state={OrganizationControlState.add}
            organization={{ organizationid: "", name: "", inn: "", namefull: "", kpp: "", pookpo: "", code: "" }}
            saveCallback={onSaveCallback}
            cancelCallback={onCancelCallback}
          />
        )}
      </Col>
    </Container>
  );
};

export default OrganizationList;
