import React from "react";
import { Link } from "react-router-dom";

import { Container } from "react-bootstrap";

import s from "./Footer.module.scss";

export const Footer: React.FC = () => {
  return (
    <>
      <footer className={s.footer}>
        <Container className="py-5">
          <div>
            <div className="my-2">
              <Link to="/help">Справка</Link>
            </div>
            <div className="my-2">
              <Link to="/about">О нас</Link>
            </div>
            <div className="my-2">
              <Link to="/contacts">Контакты</Link>
            </div>
            <div className="my-2">
              <Link to="/privacy">Политика конфиденциальности</Link>
            </div>
          </div>
          <hr />
          2022 OOO "Негоциант"
        </Container>
      </footer>
    </>
  );
};
