import React from "react";
import { Alert, Col, Container, Row, Spinner } from "react-bootstrap";
import ClientShopItem from "./ClientShopItem";
import { Pagination } from "../../components/Pagination";
import EmptyBox from "../../components/EmptyBox";
import ShowcaseService, { Showcase } from "../../services/ShowcaseService";

import cn from "classnames";
import s from "./Shop.module.scss";

const ClientShop: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [items, setItems] = React.useState([] as Showcase[]);
  const [error, setError] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalcount, setTotalcount] = React.useState(0);
  const limit = 16;
  const pageCount = Math.floor(totalcount / limit) + (totalcount % limit ? 1 : 0);

  const onChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const effectFunc = () => {
    setLoading(true);
    setError("");
    ShowcaseService.getAll(currentPage, limit)
      .then((res) => {
        setItems(res.data.items /* .filter((item) => item.price) */);
        setTotalcount(res.data.totalcount);
      })
      .catch((e: any) => {
        console.log(e);

        if (e.response) {
          console.log(e.response.data);
          setError(e.response.data);
        } else {
          console.log("Error", e.message);
          setError(e.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  React.useEffect(effectFunc, [currentPage]);
  if (loading)
    return (
      <Container className="m-5 text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">загрузка...</span>
        </Spinner>
      </Container>
    );

  if (error || !items)
    return (
      <Container className="text-center">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );

  if (items.length === 0) return <EmptyBox />;
  return (
    <Container className={cn(" my-5", s.shop)}>
      <div className="d-none d-md-block">
        <h1>Доступные пакеты лицензий</h1>

        <hr className="text-dark" />
      </div>
      {items.length === 1 && (
        <div className="text-center g-4 w-100">
          <div>
            {items.map((item) => (
              <ClientShopItem key={item.showcaseid} {...item} />
            ))}
          </div>
        </div>
      )}
      {items.length > 1 && (
        <Row className="text-center g-4 mx-auto w-100">
          {items.map((item) => (
            <Col xs={12} sm={12} md={6} lg={4} xl={4} className="g-4 px-4">
              <ClientShopItem key={item.showcaseid} {...item} />
            </Col>
          ))}
        </Row>
      )}
      {pageCount > 1 && (
        <Container className="p-4">
          <Pagination currentPage={currentPage} onChangePage={onChangePage} pageCount={pageCount} />
        </Container>
      )}
    </Container>
  );
};

export default ClientShop;
