import { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import { selectUser } from "../../redux/user/selectors";
import device from "../../assets/img/svg/device.svg";
import log from "../../assets/img/svg/log.svg";
import downloads from "../../assets/img/svg/downloads.svg";
import sandwich from "../../assets/img/svg/sandwich.svg";
import shop from "../../assets/img/svg/shop.svg";
import useroffcanvas from "../../assets/img/svg/useroffcanvas.svg";
import userprofileoffcanvas from "../../assets/img/svg/userprofileoffcanvas.svg";
import license from "../../assets/img/svg/license.svg";
import invoiceoffcanvas from "../../assets/img/svg/invoiceoffcanvas.svg";
import cartoffcanvas from "../../assets/img/svg/cartoffcanvas.svg";
import logoutoffcanvas from "../../assets/img/svg/logoutoffcanvas.svg";
import organizationoffcanvas from "../../assets/img/svg/organizationoffcanvas.svg";
import installationoffcanvas from "../../assets/img/svg/installationoffcanvas.svg";
import Cookies from "js-cookie";

import { logoutUser } from "../../redux/user/asyncActions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import LoginModal from "../LoginModal";

import cn from "classnames";
import s from "./Header.module.scss";

function OffcanvasModule() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoginShow, setIsLoginShow] = useState(false);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUser);

  const onLoginClick = () => {
    handleClose();
    setIsLoginShow(!isLoginShow);
  };

  return (
    <>
      <div>
        <img className={cn("p-3 m-0", s.sandwich)} alt="sandwich" src={sandwich} onClick={handleShow} />
      </div>

      <Offcanvas show={show} onHide={handleClose} responsive="md" scroll placement="top" keyboard className={cn("h-100", s.offcanvas)} style={{ backgroundColor: "#2c4758", zIndex: "100000" }}>
        <Offcanvas.Header closeButton closeVariant="white"></Offcanvas.Header>
        <Offcanvas.Body>
          {user && (
            <div className="text-center w-100 pt-3 mb-3">
              <div>
                <img alt="useroffcanvas" src={useroffcanvas} />
              </div>
              <div>{user.email}</div>
              <div>
                <Link to="userprofile" className="dropdown-item" onClick={handleClose}>
                  <img alt="userprofileoffcanvas" className="mx-2" src={userprofileoffcanvas} />
                  <span>Параметры аккаунта</span>
                </Link>
              </div>
            </div>
          )}
          {!user && (
            <div className="text-center w-100 pt-3 mb-3">
              <div>
                <img alt="useroffcanvas" src={useroffcanvas} />
              </div>
              <div>
                <button className="btn p-0 login-btn-offcanvas" type="button" onClick={onLoginClick} aria-label="Закрыть">
                  Логин
                </button>
              </div>
            </div>
          )}
          <hr />
          <div className="px-5 py-4 pt-0" style={{ backgroundColor: "#2c4758" }}>
            <div className="">
              <Link to="/shop" className="offcanvas-link text-white align-items-center row py-3" onClick={handleClose}>
                <div className="col-2 col-sm-2 col-md-1">
                  <img alt="shop" src={shop} />
                </div>
                <div className="col-10 col-sm-10 col-md-11">Магазин</div>
              </Link>

              {user && (
                <>
                  <Link to="/cart" className="offcanvas-link text-white align-items-center row py-3" onClick={handleClose}>
                    <div className="col-2 col-sm-2 col-md-1">
                      <img alt="cartoffcanvas" src={cartoffcanvas} />
                    </div>
                    <div className="col-10 col-sm-10 col-md-11">Корзина</div>
                  </Link>

                  <hr />
                </>
              )}
              <Link to="/" className="offcanvas-link text-white align-items-center row py-3" onClick={handleClose}>
                <div className="col-2 col-sm-2 col-md-1">
                  <img alt="downloads" src={downloads} />
                </div>
                <div className="col-10 col-sm-10 col-md-11">Мои загрузки</div>
              </Link>
              {user && (
                <>
                  <Link to="/license" className="offcanvas-link text-white align-items-center row py-3" onClick={handleClose}>
                    <div className="col-2 col-sm-2 col-md-1">
                      <img alt="license" src={license} />
                    </div>
                    <div className="col-10 col-sm-10 col-md-11">Лицензии</div>
                  </Link>
                  <Link to="/device" className="offcanvas-link text-white align-items-center row py-3" onClick={handleClose}>
                    <div className="col-2 col-sm-2 col-md-1">
                      <img alt="device" src={device} />
                    </div>
                    <div className="col-10 col-sm-10 col-md-11">Устройства</div>
                  </Link>
                  <Link to="/downloadlog" className="offcanvas-link text-white align-items-center row py-3" onClick={handleClose}>
                    <div className="col-2 col-sm-2 col-md-1">
                      <img alt="log" src={log} />
                    </div>
                    <div className="col-10 col-sm-10 col-md-11">Журнал загрузок</div>
                  </Link>
                  <Link to="/installation" className="offcanvas-link text-white align-items-center row py-3" onClick={handleClose}>
                    <div className="col-2 col-sm-2 col-md-1">
                      <img alt="installationoffcanvas" src={installationoffcanvas} />
                    </div>
                    <div className="col-10 col-sm-10 col-md-11">Инсталяции</div>
                  </Link>
                  <hr />
                  <Link to="/invoice" className="offcanvas-link text-white align-items-center row py-3" onClick={handleClose}>
                    <div className="col-2 col-sm-2 col-md-1">
                      <img alt="invoiceoffcanvas" src={invoiceoffcanvas} />
                    </div>
                    <div className="col-10 col-sm-10 col-md-11">Мои заказы</div>
                  </Link>
                  <Link to="/organization" className="offcanvas-link text-white align-items-center row py-3" onClick={handleClose}>
                    <div className="col-2 col-sm-2 col-md-1">
                      <img alt="organizationoffcanvas" src={organizationoffcanvas} />
                    </div>
                    <div className="col-10 col-sm-10 col-md-11">Мои юр. лица</div>
                  </Link>
                </>
              )}
            </div>

            {user && (
              <div>
                <hr />
                <Link
                  to={""}
                  className="offcanvas-link text-white align-items-center row py-3"
                  onClick={(e) => {
                    dispatch(logoutUser());
                    localStorage.removeItem("token");
                    Cookies.remove("accessToken");
                    window.location.href = "/";
                  }}
                >
                  <div className="col-2 col-sm-2 col-md-1">
                    <img alt="logoutoffcanvas" src={logoutoffcanvas} />
                  </div>
                  <div className="col-10 col-sm-10 col-md-11">Выйти</div>
                </Link>
              </div>
            )}
            <div className="text-center pt-4 t-w">2022 OOO "InterTech"</div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      {isLoginShow && (
        <LoginModal
          handleClose={() => {
            setIsLoginShow(false);
          }}
        />
      )}
    </>
  );
}

export default OffcanvasModule;
