import React from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import ChangeDeleteAccountItem from "./ChangeDeleteAccountItem";
import ChangeEmailItem from "./ChangeEmailItem";
import ChangePasswordItem from "./ChangePasswordItem";
import ChangeUserDataItem from "./ChangeUserDataItem";

import cn from "classnames";
import s from "./UserProfile.module.scss";

const UserProfilePage: React.FC = () => {
  enum Page {
    userdata,
    email,
    password,
    accdelete,
  }

  const [page, setPage] = React.useState<Page>(Page.userdata);

  const changePageHandler = (event: React.MouseEvent<HTMLButtonElement>, p: Page) => {
    event.stopPropagation();
    setPage(p);
    return false;
  };

  return (
    <>
      <div className="my-5 d-none d-md-block">
        <h1 className={cn("ps-4", s.title)}>Параметры аккаунта</h1>
        <hr className="text-dark" />
      </div>
      <Row className={s.account}>
        <Col xs={12} sm={4} md={4} lg={4} xl={4} className={cn("p-0 fs-4 animate__animated animate__fadeInLeft", s.nav_column)}>
          <Nav className={cn(" flex-column", s.nav)}>
            <Nav.Link
              className={cn("param-item col-12", s.nav_link, { [s.nav_link_active]: page === Page.userdata })}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => changePageHandler(event, Page.userdata)}
            >
              Имя
            </Nav.Link>
            <Nav.Link
              className={cn("param-item col-12", s.nav_link, { [s.nav_link_active]: page === Page.email })}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => changePageHandler(event, Page.email)}
            >
              Адрес электронной почты
            </Nav.Link>
            <Nav.Link
              className={cn("param-item col-12", s.nav_link, { [s.nav_link_active]: page === Page.password })}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => changePageHandler(event, Page.password)}
            >
              Пароль
            </Nav.Link>
            <Nav.Link
              className={cn("param-item col-12", s.nav_link, { [s.nav_link_active]: page === Page.accdelete })}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => changePageHandler(event, Page.accdelete)}
            >
              Удалить аккаунт
            </Nav.Link>
          </Nav>
        </Col>

        <Col xs={12} sm={8} md={8} lg={8} xl={8} className="p-4" style={{ color: "#3e4652" }}>
          <Container>
            {page === Page.userdata && <ChangeUserDataItem />}
            {page === Page.email && <ChangeEmailItem />}
            {page === Page.password && <ChangePasswordItem />}
            {page === Page.accdelete && <ChangeDeleteAccountItem />}
          </Container>
        </Col>
      </Row>
    </>
  );
};

export default UserProfilePage;
