import React from "react";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectUser } from "../../redux/user/selectors";
import { setUser } from "../../redux/user/slice";
import { User } from "../../redux/user/types";
import UserProfileService from "../../services/UserProfileService";

const ChangeEmailItem: React.FC = () => {
  const { user } = useAppSelector(selectUser);
  const [email, setEmail] = React.useState(user?.email);
  const dispatch = useAppDispatch();

  const onClickApply = () => {
    if (!email) return;
    UserProfileService.emailUpdate({ email })
      .then((res) => {
        dispatch(setUser({ ...user, email } as User));
      })
      .catch((e: any) => {
        // console.log('except', e);
        if (e.response) {
          alert(e.response.data.message);
        } else {
          alert(e.message);
        }
      });
  };

  return (
    <div>
      <div className="mb-3">
        <label className="form-label">Адрес электронной почты</label>
        <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />

        <div className="form-text">Мы никогда никому не передадим вашу электронную почту.</div>
      </div>
      <Button variant="secondary" onClick={onClickApply}>
        Применить
      </Button>
    </div>
  );
};

export default ChangeEmailItem;
