import React from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import CartItem from "./CartItem";
import { selectCart } from "../../redux/cart/selectors";
import { selectCurrency } from "../../redux/currency/selectors";
import { cartClear } from "../../redux/cart/asyncActions";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import EmptyBox from "../../components/EmptyBox";
import cartclearall from "../../assets/img/svg/cartclearall.svg";

import s from "./Cart.module.scss";

const CartPage: React.FC = () => {
  const { totalPrice, items } = useAppSelector(selectCart);
  const currency = useAppSelector(selectCurrency);
  const dispatch = useAppDispatch();
  const totalCount = items.reduce((sum: number, item: any) => sum + item.cnt, 0);

  const onClickClear = () => {
    if (window.confirm("Очистить корзину?")) {
      dispatch(cartClear());
    }
  };

  return (
    <>
      <Container className="my-5">
        <div className={s.cart}>
          <Row className="align-items-center justify-content-between">
            <Col>
              <h1 className="d-none d-md-block">Корзина</h1>
            </Col>
            <Col className="text-end">
              <Button variant="light" onClick={onClickClear}>
                Очистить
                <img src={cartclearall} alt="cartclearall" />
              </Button>
            </Col>
          </Row>

          <div className="content__items">
            {items.map((item: any) => (
              <CartItem key={item.nomenclatureid} {...item} />
            ))}
          </div>
          {!(items.length > 0) && (
            <EmptyBox message=" ">
              <p className="mb-0">
                В вашей корзине пусто,{" "}
                <Link to="/shop">
                  <Button className="style-btn-action rounded px-2 py-0">вернитесь в магазин</Button>
                </Link>
              </p>
              <p>чтобы добавить товар</p>
            </EmptyBox>
          )}
          <div className="row text-center justify-content-between my-3">
            <div className="col-6 col-xl-3 col-lg-3 col-md-4 col-sm-5 text-start">
              <p>
                Всего позиций: <b>{totalCount}</b>
              </p>
            </div>
            <div className="col-6 col-xl-4 col-lg-4 col-md-5 col-sm-6 text-end">
              <p>
                Сумма заказа:{" "}
                <b>
                  {totalPrice.toLocaleString()} {currency.nameshort}
                </b>
              </p>
            </div>
          </div>
          {items.length > 0 && (
            // <button type="button" className="btn style-btn-action col-12 " onClick={onShowCartOrderModal}>
            //   Сформировать заказ
            // </button>

            <Link to="/order" className="col-12 text-white fs-4 py-3">
              <Button variant="secondary w-100">Сформировать заказ</Button>
            </Link>
          )}
        </div>
      </Container>
    </>
  );
};

export default CartPage;
