import React from "react";
import { Card } from "react-bootstrap";
import dateFormat from "dateformat";

export type ClientDownloadItemProps = {
  clientdownloadid: string;
  downloaddate: Date;
  clientagent: string;
  recorddatecreate: Date;
  ipaddress: string;
  productid: string;
  productname: string;
};

const ClientDownloadLogItem: React.FC<ClientDownloadItemProps> = ({ downloaddate, clientagent, ipaddress, productname }) => {
  return (
    <Card className="mb-5 text-dark p-4">
      <p>Дата: {dateFormat(downloaddate, " d mmmm, yyyy, HH:MM")}</p>
      {/* <p>агент закачки: {clientagent}</p> */}
      <p>IP адрес: {ipaddress}</p>
      <p>Продукт: {productname}</p>
    </Card>
  );
};

export default ClientDownloadLogItem;
