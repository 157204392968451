import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import cn from "classnames";
import s from "./Contacts.module.scss";

const Contacts: React.FC = () => {
  return (
    <Container className={cn("my-5 fs-6", s.contacts)}>
      <h1 className="fw-bold">Контакты</h1>
      <Row>
        <Col>
          <p className="fw-bold">Компания</p>
          <p>ООО «Негоциант»</p>
          <p className="fw-bold">По вопросам сотрудничества:</p>
          <p>+7 (391) 203-00-15</p> <p>Волков Виталий</p> <p>info@itasset.pro</p>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
};

export default Contacts;
